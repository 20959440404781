<template>
  <v-app>
    <v-app-bar color="#fff" app class="header">
      <Header />
    </v-app-bar>
    <v-main style="background: #efefef">
      <div class="r-container tw-pb-20">
        <div class="tw-flex tw-flex-wrap tw--mx-2">
          <div class="px-2 tw-w-full tw-mb-3 lg:tw-w-3/4">
            <router-view></router-view>
          </div>
          <div class="tw-w-full tw-mb-3 lg:tw-w-1/4" ref="adverting">
            <Advertising :width="width" />
          </div>
        </div>
      </div>
    </v-main>
    <v-footer padless color="#5907b4">
      <v-col class="text-center tw-bg-purple-600 tw-flex-wrap tw-pt-1.5 tw-pb-0.5 tw-text-gray-700 tw-flex tw-justify-center tw-items-center" cols="12">
        <a class="tw-flex tw-mx-2" href="https://t.me/anvarjamgirov">
          <span class="tw-text-gray-100 hover:tw-text-gray-300">Anvar Jamgirov</span>
        </a>
        <a class="tw-flex tw-mx-2" href="https://t.me/Mamatov_Rashid">
          <span class="tw-text-gray-100 hover:tw-text-gray-300">Rashid Mamatov</span>
        </a>
        <a class="tw-flex tw-mx-2" href="https://t.me/KhumoyunUrinboev">
          <span class="tw-text-gray-100 hover:tw-text-gray-300">Xumoyun O'rinboyev</span>
        </a>
      </v-col>
      <v-col class="text-center tw-pt-0.5 tw-pb-1 tw-text-gray-100" cols="12"> <strong> © Lupa</strong> - {{ new Date().getFullYear() }}</v-col>
    </v-footer>
    <!-- <v-footer padless color="primary lighten-1">
      <v-card class="flex text-center" flat tile>
        <v-card-text class="tw-py-1.5 white--text text-center tw-flex tw-justify-center tw-items-center tw-flex-wrap tw-bg-blue-600">
          <a class="tw-flex tw-mx-2" href="https://t.me/anvarjamgirov"><span class="tw-text-white hover:tw-text-gray-300">Anvar Jamgirov</span></a> <a class="tw-flex tw-mx-2" href="https://t.me/Mamatov_Rashid"><span class="tw-text-white hover:tw-text-gray-300">Rashid Mamatov</span></a> <a class="tw-flex tw-mx-2" href="https://t.me/KhumoyunUrinboev"><span class="tw-text-white hover:tw-text-gray-300">Xumoyun O'rinboyev</span></a>
        </v-card-text>
        <v-card-text class="py-1 white--text text-center tw-bg-gray-400"> <strong> © Lupa</strong> - {{ new Date().getFullYear() }}</v-card-text>
      </v-card>
    </v-footer> -->
  </v-app>
</template>
<script>
import { mapActions } from "vuex";
import Advertising from "./components/Advertising.vue";
import Header from "./components/Header.vue";
export default {
  name: "App",
  components: {
    Header,
    Advertising,
    // vueTelegramLogin,
  },
  data() {
    return {
      width: 0,
    };
  },
  mounted() {
    this.width = this.$refs.adverting.clientWidth;
    this.getAdvertisement();
  },
  methods: {
    ...mapActions(["getAdvertisement"]),
  },
  watch: {
    "$route.params": function () {
      this.getAdvertisement();
    },
  },
};
</script>
<style lang="scss">
.r-container {
  max-width: 1280px;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 1rem;
  @media screen and (max-width: 640px) {
    padding: 8px;
  }
}
.header {
  .v-toolbar__content {
    padding: 0;
  }
}
.v-card__title {
  word-break: normal !important;
}
</style>
