<template>
  <div class="r-container tw-py-0 tw-flex tw-items-center">
    <router-link to="/" class="d-flex align-center">
      <v-icon style="color: #5907b4; font-size: 40px"> mdi-magnify </v-icon>
      <p class="tw-font-bold tw-text-3xl mb-0 tw-font-serif" style="color: #5907b4">Lupa</p>
    </router-link>
    <div class="lg:tw-flex tw-items-center lg:tw-ml-14 xl:tw-ml-20 tw-hidden tw--left-full">
      <v-btn text class="tw-flex tw-mx-2 tw-py-1 tw-px-3 tw-rounded-md tw-font-medium" to="/">To'plamlar</v-btn>
      <v-btn text class="tw-flex tw-mx-2 tw-py-1 tw-px-3 tw-rounded-md tw-font-medium" to="/mytests">Mening testlarim</v-btn>
      <v-btn text class="tw-flex tw-mx-2 tw-py-1 tw-px-3 tw-rounded-md tw-font-medium" to="/about">Biz haqimizda</v-btn>
    </div>

    <v-spacer></v-spacer>
    <div :class="TOKEN ? 'lg:tw-hidden' : 'lg:tw-flex '" class="tw-hidden">
      <vue-telegram-login v-if="screen" mode="callback" telegram-login="lupaRobot" @callback="loginCallbackFunction" />
    </div>
    <div :class="show ? 'tw-translate-x-0' : 'tw-translate-x-full'" class="tw-fixed tw-w-screen tw-transition-all tw-duration-700 tw-top-0 tw-h-screen tw-right-0 tw-z-50 tw-transform lg:tw-hidden">
      <div class="tw-relative tw-h-full tw-w-full">
        <v-btn @click="show = false" class="tw-absolute tw-top-2 tw-right-3 tw-z-30" fab small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div @click="show = false" class="tw-absolute tw-left-0 tw-top-0 tw-bg-black tw-w-full tw-h-full tw-bg-opacity-25"></div>
        <div class="tw-w-96 tw-max-w-full tw-bg-gradient-to-br tw-from-purple-600 tw-to-purple-500 tw-h-full tw-absolute tw-right-0 tw-top-0 tw-z-20 tw-p-3 tw-py-16 tw-flex tw-flex-col">
          <v-btn class="mb-2 tw-flex tw-mx-2 tw-py-1 tw-px-3 tw-rounded-md tw-font-medium" to="/">To'plamlar</v-btn>
          <v-btn class="mb-2 tw-flex tw-mx-2 tw-py-1 tw-px-3 tw-rounded-md tw-font-medium" to="/mytests">Mening testlarim</v-btn>
          <v-btn class="mb-2 tw-flex tw-mx-2 tw-py-1 tw-px-3 tw-rounded-md tw-font-medium" to="/about">Biz haqimizda</v-btn>
          <v-spacer></v-spacer>
          <div :class="TOKEN ? 'tw-hidden' : ''">
            <vue-telegram-login v-if="!screen" mode="callback" telegram-login="lupaRobot" @callback="loginCallbackFunction" />
          </div>
          <v-btn @click="Logout()" class="white--text px-2 px-sm-4" v-if="TOKEN" color="#5907b4">Chiqish<v-icon>mdi-logout</v-icon></v-btn>
        </div>
      </div>
    </div>
    <v-btn @click="Logout()" class="white--text tw-hidden lg:tw-flex px-2 px-sm-4" v-if="TOKEN" color="#5907b4">Chiqish<v-icon>mdi-logout</v-icon></v-btn>
    <v-app-bar-nav-icon class="lg:tw-hidden" color="primary " @click.stop="show = true"></v-app-bar-nav-icon>
  </div>
</template>

<script>
import { vueTelegramLogin } from "vue-telegram-login";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  components: { vueTelegramLogin },
  data() {
    return {
      show: false,
      screen: false
    };
  },
  computed: {
    ...mapGetters(["TOKEN"]),
  },
  mounted() {
    this.screen = window.innerWidth > 1024 ? true : false
    this.getToken();
  },
  methods: {
    ...mapActions(["getToken"]),
    loginCallbackFunction(user) {
      this.$store.dispatch("getAuth", user);
    },
    Logout() {
      localStorage.removeItem('token');
      this.getToken();
      this.dialog = false;
    },

  },
  watch: {
    "$route": function () {
      this.show = false
    },
  },
};
</script>

<style lang="scss" scoped></style>
