import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Workplace from '../views/Workplace.vue'
import Questions from '../views/Questions.vue'
import Random from '../views/Random.vue'
import Tests from '../views/Tests.vue'
import MyTests from '../views/MyTests.vue'
import MyTestDetail from '../views/MyTestDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/workplace/:id/',
    component: Workplace,
    children: [
      {
        path: 'questions',
        name: 'Questions',
        component: Questions
      },
      {
        path: 'random',
        name: 'Random',
        component: Random
      },
      {
        path: 'testing',
        name: 'Tests',
        component: Tests
      },
    ],
  },
  {
    path: '/mytests',
    name: 'MyTests',
    component: MyTests
  },
  {
    path: '/mytestdetail/:id',
    name: 'MyTestDetail',
    component: MyTestDetail
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
