<template>
  <div>
    <div v-if="copy" class="tw-fixed tw-w-full tw-bg-black tw-bg-opacity-40 tw-z-50  tw-h-full tw-top-0 tw-left-0 tw-flex tw-items-center tw-justify-center"><p class="tw-text-9xl">Copied !</p></div>
    <v-card class="tw-p-2.5 sm:tw-px-3 sm:tw-py-2 tw-mb-2.5 sm:tw-mb-3">
      <p
        v-if="COLLECTIONS_WORKPLACE()"
        class="tw-text-lg tw-font-medium tw-mt-2 tw-leading-tight sm:tw-leading-snug"
      >
        {{ COLLECTIONS_WORKPLACE(router).name }}
      </p>

      <div class="tw-mt-3 sm:tw-my-4 tw-flex tw-flex-wrap tw--mx-1 sm:tw--mx-2">
        <div class="tw-px-1 sm:tw-px-2 tw-w-fu sm:tw-w-auto tw-mb-2 sm:tw-mb-0">
          <router-link
            active-class="tw-bg-blue-500 white--text"
            class="tw-text-blue-500 sm:tw-text-xl tw-border tw-border-blue-500 tw-rounded tw-px-3 tw-py-0.5"
            to="questions"
            >Savollar</router-link
          >
        </div>
        <div class="tw-px-1 sm:tw-px-2 tw-w-fu sm:tw-w-auto tw-mb-2 sm:tw-mb-0">
          <router-link
            active-class="tw-bg-blue-500 white--text"
            class="tw-text-blue-500 sm:tw-text-xl tw-border tw-border-blue-500 tw-rounded tw-px-3 tw-py-0.5"
            to="random"
            >Tasodifiy savollar</router-link
          >
        </div>
        <div class="tw-px-1 sm:tw-px-2 tw-w-fu sm:tw-w-auto tw-mb-2 sm:tw-mb-0">
          <router-link
            active-class="tw-bg-blue-500 white--text"
            class="tw-text-blue-500 sm:tw-text-xl tw-border tw-border-blue-500 tw-rounded tw-px-3 tw-py-0.5"
            to="testing"
            >Test yechish</router-link
          >
        </div>
      </div>
    </v-card>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Workplace",
  data: () => ({
    router: "",
    copy: false
  }),
  mounted() {
    this.getCollections();
    this.router = this.$route.params.id;
    window.addEventListener("keydown", (e) => {
      if (e.shiftKey) {
        this.copy = true
        setTimeout(() => {
          this.copy = false
        }, 100);
        let code = `
        
var allElements = document.getElementsByTagName("*");
for (var i = 0, len = allElements.length; i < len; i++) {
    allElements[i].style.setProperty("user-select", "auto", "important");
}
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
document.documentElement.style.setProperty("user-select", "auto");
var toInsert = document.createElement("div");
const postUrl = "https://lupa.jamgirov.uz/api/core/collections/${this.$route.params.id}/quizzes/?search=";
window.addEventListener("keydown", (e) => {
  if (e.shiftKey) {
    const text = window.getSelection().toString();
    toInsert.style.position = "fixed";
    toInsert.style.bottom = "0px";
    toInsert.style.textAlign = "center";
    toInsert.style.width = "100%";
    toInsert.style.fontSize = "14px";
    toInsert.style.zIndex = "1000";
    postSelectedText(\`$\{postUrl}$\{text}\`).then(async (data) => {
      toInsert.innerHTML = data[0]["options"][0]["text"];
      document.body.appendChild(toInsert);
      await sleep(4000);
      toInsert.innerHTML = "";
      document.body.appendChild(toInsert);
    }).catch(error=>{
      console.log("shit", error)
    });
  }
});

async function postSelectedText(url = "") {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
  });
  return response.json();
}
        `;
        navigator.clipboard.writeText(code);
      }
    });
  },
  computed: {
    ...mapGetters(["COLLECTIONS_WORKPLACE"]),
  },
  methods: {
    ...mapActions(["getCollections"]),
  },
};
</script>

<style lang="scss" scoped></style>
