<template>
  <div>
    <v-card class="tw-px-3 tw-py-2 tw-mb-3">
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="search" dense clearable hide-details="auto" outlined label="Qidirish" prepend-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select v-model="education_degree" :items="EDUCATION_DEGREES" item-text="name" item-value="id" label="Ta'lim turi" dense clearable hide-details="auto" outlined></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-select v-model="education_course" :items="EDUCATION_COURSES" item-text="name" item-value="id" label="Ta'lim bosqichi" dense clearable hide-details="auto" outlined></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-select v-model="education_year" :items="EDUCATION_YEARS" item-text="name" item-value="id" label="O'quv yili" dense clearable hide-details="auto" outlined></v-select>
        </v-col>
      </v-row>
    </v-card>
    <div class="tw-flex tw-flex-wrap tw--mx-1.5">
      <div class="tw-w-full tw-px-1.5">
        <v-card v-if="(search || education_course || education_degree || education_year) && !COLLECTIONS(search, education_course, education_degree, education_year).length">
          <v-card-text>
            <div class="tw-flex tw-justify-center tw-items-center tw-text-lg tw-py-4">Natija topilmadi 🤷🏻‍♂️</div>
          </v-card-text>
        </v-card>
      </div>
      <div class="tw-w-full md:tw-w-1/2 tw-px-1.5 tw-mb-3" v-for="(collection, index) in COLLECTIONS(search, education_course, education_degree, education_year)" :key="index">
        <v-card :color="collection.color" :to="`/workplace/${collection.id}/questions`" class="tw-h-full tw-flex tw-flex-col">
          <v-card-title class="tw-break-normal tw-flex-1 tw-items-start">
            {{ collection.name }}
          </v-card-title>
          <v-card-text class="pb-1">
            <div class="tw-flex tw-flex-wrap tw-justify-between tw-text-black tw-text-base">
              <div>
                {{ collection.education_year.name }}
              </div>
              <div>
                {{ collection.education_course.name }}
              </div>
            </div>
            <div class="tw-flex tw-flex-wrap tw-justify-end">
              <div>
                {{ collection.education_degree.name }}
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="px-3 tw-pt-0">
            <v-list-item class="grow">
              <v-row align="center" justify="start">
                <v-icon class="mr-1"> mdi-chat-question-outline </v-icon>
                <span class="subheading mr-2">{{ collection.quizzes_count }}</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1"> mdi-google-controller </v-icon>
                <span class="subheading">{{ collection.tests_count }}</span>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Home",

  components: {},
  data: () => ({
    search: "",
    education_degree: "",
    education_course: "",
    education_year: "",
  }),
  mounted() {
    this.getCollections();
    this.getEducationCourses();
    this.getEducationDegrees();
    this.getEducationYears();
  },
  computed: {
    ...mapGetters(["COLLECTIONS", "EDUCATION_COURSES", "EDUCATION_DEGREES", "EDUCATION_YEARS"]),
  },
  methods: {
    ...mapActions(["getCollections", "getEducationCourses", "getEducationDegrees", "getEducationYears", "filterCollection"]),
  },
};
</script>
