<template>
  <div>
    <v-card class="tw-mb-2 tw-p-3 tw-relative">
      <v-row>
        <v-col cols="12" class="tw-p-2 sm:tw-p-4">
          <v-text-field v-model="search" dense clearable hide-details="auto" outlined label="Qidirish" prepend-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="search && !COLLECTION_QUIZZES(search).length">
      <v-card-text>
        <div class="tw-flex tw-justify-center tw-items-center tw-text-lg tw-py-3 sm:tw-py-5">Natija topilmadi 🤷🏻‍♂️</div>
      </v-card-text>
    </v-card>
    <v-card class="tw-mb-2 tw-pb-2.5 sm:tw-pb-4 tw-relative" v-for="(collection_quiz, index) in collection_quizzes" :key="index">
      <v-card-title class="tw-p-2.5 sm:tw-p-4 tw-text-lg sm:tw-text-xl tw-break-normal tw-leading-normal" v-html="collection_quiz.question"></v-card-title>
      <v-chip class="ma-2" x-small> {{ collection_quiz.complexity.name }} : {{ collection_quiz.complexity.ball }} ball </v-chip>
      <v-card-text v-for="(option, index) in collection_quiz.options" :key="index" class="tw-py-2 tw-px-2 sm:tw-px-4 lg:tw-py-2 xl:tw-py-3.5 tw-flex tw-items-center">
        <v-icon v-if="!option.is_correct" color="red" class="tw-mr-3"> mdi-checkbox-blank-circle-outline </v-icon>
        <v-icon v-else color="green" class="tw-mr-3"> mdi-checkbox-marked-circle-outline </v-icon>
        <div v-html="option.text"></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Questions",
  data: () => ({
    search: "",
  }),
  mounted() {
    this.getCollectionQuizzes(this.$route.params.id);
  },
  computed: {
    ...mapGetters(["COLLECTION_QUIZZES"]),
    collection_quizzes(){
      return this.COLLECTION_QUIZZES(this.search)
    }
  },
  methods: {
    ...mapActions(["getCollectionQuizzes"]),
  },
};
</script>

<style lang="scss" scoped></style>
