<template>
  <div>
    <v-card v-if="!TOKEN" class="tw-h-fulll">
      <p class="tw-p-2.5 mb-1 sm:tw-py-5 sm:tw-px-3 sm:tw-text-xl">Kechirasiz siz tizimga kirmagansiz. Kirish uchun telegram orqali ro'yhatdan o'ting. <span class="lg:tw-hidden">Ro'yhatdan o'tish tugmasi menyu bo'limida.</span></p>
    </v-card>
    <div v-if="MYTESTDETAIL">
      <v-card class="mb-2">
        <v-card-title>
          {{ MYTESTDETAIL.collection.name }}
        </v-card-title>
        <v-card-text>
            <span class="tw-text-base"> To'plagan bali {{ MYTESTDETAIL.ball }} </span><span class="tw-text-base tw-border tw-border-gray-500 tw-rounded-md tw-font-medium tw-px-3">{{parseInt(MYTESTDETAIL.ball / MYTESTDETAIL.collection.max_ball * 100)}} %</span>

          <br />
          <span class="tw-text-base">
            Boshlanish vaqti:
            {{ new Date(MYTESTDETAIL.start_time).toLocaleString() }}
          </span>
          <br />
          <span class="tw-text-base">
            Tugash vaqti:
            {{ new Date(MYTESTDETAIL.end_time).toLocaleString() }}
          </span>
        </v-card-text>
      </v-card>
      <v-card class="tw-mb-2 tw-relative" v-for="(quiz, index) in MYTESTDETAIL.quizzes" :key="index">
        <v-card-title class="tw-break-normal tw-leading-normal" v-html="quiz.question"></v-card-title>
        <v-chip class="ma-2" x-small> {{ quiz.complexity.name }} : {{ quiz.complexity.ball }} ball </v-chip>
        <v-card-text v-for="(option, index) in quiz.options" :key="index" class="tw-py-1 lg:tw-py-2 xl:tw-py-3.5 black--text tw-mb-1 tw-flex tw-items-center" :class="{ 'tw-bg-green-50': option.is_correct, 'tw-bg-red-50': !option.is_correct }">
          <v-icon v-if="MYTESTDETAIL.answers.some((answer) => answer.id == option.id) && !option.is_correct" color="red" class="tw-mr-3"> mdi-close-circle-outline </v-icon>
          <v-icon v-else-if="MYTESTDETAIL.answers.some((answer) => answer.id == option.id) && option.is_correct" color="green" class="tw-mr-3"> mdi-checkbox-marked-circle-outline </v-icon>
          <v-icon v-else class="tw-mr-3">mdi-checkbox-blank-circle-outline</v-icon>
          <div v-html="option.text"></div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MyTestDetail",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["TOKEN", "MYTESTDETAIL"]),
  },
  mounted() {
    this.getToken();
    this.TOKEN ? this.getMyTestDetail(this.$route.params.id) : "";
  },
  methods: {
    ...mapActions(["getToken","getMyTestDetail"]),
  },
};
</script>

<style lang="scss" scoped></style>
