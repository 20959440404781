<template>
  <div class="px-2 lg:tw-fixed tw-items-center tw-flex tw-justify-center" :style="`width: ${width}px;`">
      <a :href="ADVERTISEMENT.link" target="_blank" class="tw-border tw-bg-white tw-border-gray-300 tw-shadow-md tw-block tw-rounded-lg tw-overflow-hidden tw-relative tw-pb-3 tw-w-full sm:tw-w-96 tw-max-w-full">
        <div v-if="ADVERTISEMENT.photo_url" class="tw-w-full tw-border-b tw-border-gray-300 tw-overflow-hidden">
          <img class="tw-w-full" :src="ADVERTISEMENT.photo_url" />
        </div>

        <div class="tw-p-2.5 tw-text-black">
          <h1 class="tw-font-semibold tw-mb-2 tw-text-lg">{{ ADVERTISEMENT.title }}</h1>
          <p v-html="ADVERTISEMENT.description" class="tw-justify-between tw-text-sm"></p>
        </div>
        <span class="tw-text-xs tw-text-gray-500 tw-absolute tw-bottom-0 tw-right-1">reklama</span>
      </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Advertising",
  props: ["width"],
  computed: {
    ...mapGetters(["ADVERTISEMENT"]),
  },
};
</script>

<style lang="scss" scoped></style>
