<template>
  <div v-if="test">
    <v-card v-if="!TOKEN" class="tw-h-fulll">
      <p class="tw-p-2.5 mb-1 sm:tw-py-5 sm:tw-px-3 sm:tw-text-xl">Kechirasiz siz tizimga kirmagansiz. Kirish uchun telegram orqali ro'yhatdan o'ting. <span class="lg:tw-hidden">Ro'yhatdan o'tish tugmasi menyu bo'limida.</span></p>
    </v-card>
    <div v-if="TOKEN && !loading2">
      <v-card v-if="active_test">
        <v-card-title> Sizda {{ active_test.name }} fanidan yakunlanmagan testingiz mavjud. </v-card-title>
      </v-card>
      <v-card v-if="!active_test && !QUIZ_TESTS_LIST">
        <v-card-title class="tw-px-2.5 sm:tw-px-4">Testni boshlash </v-card-title>
        <v-card-text v-if="COLLECTIONS_WORKPLACE(router).name">
          <span>Fan : {{ COLLECTIONS_WORKPLACE(router).name }}</span> <br />
          <span>Max ball : {{ COLLECTIONS_WORKPLACE(router).max_ball }}</span> <br />
          <span>Berilgan vaqt : {{ COLLECTIONS_WORKPLACE(router).duration }} daqiqa</span>
        </v-card-text>
        <v-card-text class="tw-px-2.5 sm:tw-px-4">
          <v-btn @click="CreateTest()" color="primary">Boshlash</v-btn>
        </v-card-text>
      </v-card>
      <v-card v-if="!active_test && QUIZ_TESTS_LIST" class="tw-bg-white tw-p-2.5 sm:tw-py-5 sm:tw-px-3">
        <div class="tw-flex tw-justify-between">
          <div class="mb-3 tw-flex tw-flex-wrap tw--m-0.5 tw-w-full">
            <div class="btncha tw-overflow-hidden" v-for="(i, index) in QUIZ_TESTS_LIST.quizzes.length" :key="index">
              <div @click="active = i - 1" :class="{ 'tw-text-white tw-bg-indigo-800 hover:tw-bg-indigo-900': list[i - 1], 'tw-bg-gray-400  hover:tw-bg-indigo-900': i - 1 == active }" class="tw-border-t-2 tw-border-b-2 tw-border-indigo-900 tw-px-2 tw-py-1.5 tw-flex tw-items-center tw-justify-center sm:tw-py-2 sm:tw-px-3 tw-font-medium hover:tw-bg-indigo-800 hover:tw-text-white tw-cursor-pointer">
                {{ i }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <v-card class="tw-mb-2 tw-relative">
            <div class="tw-w-full tw-flex tw-justify-end tw-mt-4 tw-px-1.5 sm:tw-px-4">
              <div class="tw-bg-green-200 tw-text-lg tw-flex tw-items-center tw-justify-center tw-px-1.5 sm:tw-px-3 tw-rounded-md tw-mr-3">
                <div class="tw-block timer tw-text-sm sm:tw-text-base">
                  <vue-countdown-timer v-if="QUIZ_TESTS_LIST && QUIZ_TESTS_LIST.collection.duration" @end_callback="Answer()" :showDay="'false'" :start-time="new Date(QUIZ_TESTS_LIST.start_time)" :end-time="new Date(new Date(QUIZ_TESTS_LIST.start_time).getTime() + QUIZ_TESTS_LIST.collection.duration * 60000)" :interval="1000" label-position="begin" seconds-txt=""> </vue-countdown-timer>
                </div>
              </div>
              <div>
                <v-dialog v-model="dialog" persistent max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="px-2 px-sm-4" color="" v-bind="attrs" v-on="on"> Testni yakunlash </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5"> Testni yakunlash </v-card-title>
                    <v-card-text>Siz testni yakunlamoqchimisiz?</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn :disabled="loading" color="green darken-1" text @click="dialog = false"> Yo'q </v-btn>
                      <v-btn :loading="loading" :disabled="loading" color="green darken-1" text @click="Answer()"> Ha </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>

            <v-card-title class="tw-p-1.5 tw-pt-4 sm:tw-p-4 tw-break-normal tw-leading-normal" v-html="QUIZ_TESTS_LIST.quizzes[active].question"></v-card-title>
            <v-radio-group class="tw-mt-0" v-model="list[active]">
              <v-radio class="tw-py-1 sm:tw-py-2 xl:tw-py-3.5 tw-px-1 sm:tw-px-4" style="margin: 0 !important" v-for="(option, index) in QUIZ_TESTS_LIST.quizzes[active].options" :key="index" :value="option.id">
                <template v-slot:label>
                  <div v-html="option.text"></div>
                </template>
              </v-radio>
            </v-radio-group>
            <div class="tw-flex tw-justify-center tw-mb-3">
              <v-btn class="px-2 px-sm-4" style="border: 1px solid rgb(49, 46, 129); background: #fff; border-top-right-radius: 0; border-bottom-right-radius: 0" :disabled="active == 0" @click.prevent="Prev()"><v-icon>mdi-chevron-left</v-icon></v-btn>
              <v-btn class="px-2 px-sm-4" style="border: 1px solid rgb(49, 46, 129); background: #fff; border-left: 0; border-top-left-radius: 0; border-bottom-left-radius: 0" :disabled="active + 1 == QUIZ_TESTS_LIST.quizzes.length" @click.prevent="Next()"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
          </v-card>
        </div>
      </v-card>
    </div>
    <v-card v-if="TOKEN && loading2">
      <div class="tw-flex tw-items-center tw-justify-center tw-py-6">
        <i class="el-icon-loading tw-text-5xl"></i>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Tests",
  data: () => ({
    dialog: false,
    active_test: "",
    active: 0,
    list: [],
    test: true,
    router: "",
    loading: false,
    loading2: false,
  }),
  computed: {
    ...mapGetters(["TOKEN", "QUIZ_TESTS_LIST", "COLLECTIONS_WORKPLACE"]),
  },
  mounted() {
    this.loading2 = true;
    this.router = this.$route.params.id;
    this.TOKEN
      ? this.getTestList(this.router)
          .then((response) => {
            this.active_test = response;
            this.loading2 = false;
          })
          .catch(() => {
            this.loading2 = false;
          })
      : "";
    this.getCollections();
    this.getToken();
  },
  methods: {
    ...mapActions(["getToken", "getTestList", "getTestAnswer", "getTestCreate", "getCollections"]),
    Answer() {
      this.loading = true;
      this.getTestAnswer({ list: this.list, id: this.QUIZ_TESTS_LIST.id })
        .then((response) => {
          this.$router.push(`/mytestdetail/${response.data.id}`);
          this.dialog = false;
          this.loading = false;
          this.active_test = "";
        })
        .catch(() => {
          this.dialog = false;
          this.loading = false;
        });
    },
    CreateTest() {
      this.getTestCreate(this.router);
    },
    Next() {
      this.QUIZ_TESTS_LIST.quizzes.length > this.active ? (this.active += 1) : "";
    },
    Prev() {
      this.active >= 0 ? (this.active -= 1) : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.btncha {
  width: calc(100% / 20);
  margin-bottom: 4px;
  div {
    border-right: 2px solid rgb(49, 46, 129);
  }
  &:first-child div {
    border-left: 2px solid rgb(49, 46, 129);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:nth-child(20) div {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  &:nth-child(21) div {
    border-left: 2px solid rgb(49, 46, 129);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:nth-child(25) div {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% / 10);
    &:nth-child(11) div {
      border-left: 2px solid rgb(49, 46, 129);
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:nth-child(10) div {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
.timer div > :first-child {
  display: none;
}
.timer div > :nth-child(2) {
  display: none;
}
</style>
