<template>
  <div>
    <v-card v-if="!TOKEN" class="tw-h-fulll">
      <p class="tw-py-5 tw-px-3 sm:tw-text-xl">Kechirasiz siz tizimga kirmagansiz. Kirish uchun telegram orqali ro'yhatdan o'ting. <span class="lg:tw-hidden">Ro'yhatdan o'tish tugmasi menyu bo'limida.</span></p>
    </v-card>
    <div class="tw--m-1 tw-flex tw-flex-wrap">
      <div class="tw-p-1 md:tw-w-1/2 tw-w-full" v-for="(test, index) in MYTESTLIST" :key="index">
        <v-card :color="test.color" class="mb-2 tw-h-full tw-cursor-pointer" :to="`/mytestdetail/${test.id}`">
          <v-card-title class="tw-break-normal">
            {{ test.collection.name }}
          </v-card-title>
          <v-card-text>
            <span class="tw-text-base"> To'plagan bali {{ test.ball }} </span><span class="tw-text-base tw-border tw-border-gray-500 tw-rounded-md tw-absolute tw-bottom-3 tw-font-medium tw-right-3 tw-px-3">{{parseInt(test.ball / test.collection.max_ball * 100)}} %</span>
            <br />
            <span class="tw-text-base">
              Boshlanish vaqti:
              {{ new Date(test.start_time).toLocaleString() }}
            </span>
            <br />
            <span class="tw-text-base">
              Tugash vaqti:
              {{ new Date(test.end_time).toLocaleString() }}
            </span>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MyTests",
  mounted() {
    this.getToken();
    this.TOKEN ? this.getMyTestList() : ''
  },
  computed: {
    ...mapGetters(["TOKEN", "MYTESTLIST"]),
  },
  methods: {
    ...mapActions(["getToken", "getMyTestList"]),
  },
};
</script>

<style lang="scss" scoped></style>
