<template>
  <div>
    <v-card class="tw-mb-2 tw-relative" v-if="random_quiz && !show">
      <v-card-title class="tw-p-2 sm:tw-p-4 tw-text-lg sm:tw-text-xl tw-break-normal tw-leading-normal" v-html="random_quiz.question"></v-card-title>
      <v-chip class="ma-2" x-small> {{ random_quiz.complexity.name }} : {{ random_quiz.complexity.ball }} ball </v-chip>
      <v-radio-group class="tw-mt-0" v-model="radioGroup" hide-details>
        <v-radio class="tw-py-2 xl:tw-py-3.5 tw-px-2 sm:tw-px-4" style="margin: 0 !important" v-for="(option, index) in random_quiz.options" :key="index" :value="option.id">
          <template v-slot:label>
            <div v-html="option.text"></div>
          </template>
        </v-radio>
        <!-- <v-radio class="tw-py-2 xl:tw-py-3.5 tw-px-2 sm:tw-px-4" style="margin: 0 !important" v-for="(option, index) in random_quiz.options" :key="index" :label="option.text" :value="option.id"> </v-radio> -->
      </v-radio-group>
      <v-card-text class="tw-pt-0 sm:tw-pt-4 tw-p-2.5 sm:tw-p-4 tw-flex tw-flex-wrap tw-items-center" :class="Answers ? 'tw-justify-between' : 'tw-justify-end'">
        <div class="tw-text-base tw-text-black tw-font-medium tw-order-2 sm:tw-order-1">
          <v-chip v-if="Answers" class="ma-1 ma-sm-2" color="blue" text-color="white">
            <v-avatar left class="blue darken-4"> {{ Answers }} </v-avatar>
            Savol
          </v-chip>
          <v-chip v-if="trueAnswer" class="ma-1 ma-sm-2" color="green" text-color="white">
            <v-avatar left class="green darken-4"> {{ trueAnswer }} </v-avatar>
            To'g'ri
          </v-chip>
          <v-chip v-if="falseAnswer" class="ma-1ma-sm-2" color="red" text-color="white">
            <v-avatar left class="red darken-4"> {{ falseAnswer }} </v-avatar>
            Noto'g'ri
          </v-chip>
        </div>

        <div class="tw-w-full sm:tw-w-auto tw-flex tw-justify-end tw-order-1 sm:tw-order-2">
          <v-btn v-if="!show" :disabled="!radioGroup" color="primary" @click="showAnswer()">Javob</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="tw-mb-2 tw-relative" v-if="random_quiz && show">
      <v-card-title class="tw-p-2 sm:tw-p-4 tw-text-lg sm:tw-text-xl tw-break-normal tw-leading-normal" v-html="random_quiz.question"></v-card-title>
      <v-chip class="ma-2" x-small> {{ random_quiz.complexity.name }} : {{ random_quiz.complexity.ball }} ball </v-chip>
      <v-card-text v-for="(option, index) in random_quiz.options" :key="index" class="tw-py-1 tw-px-2 sm:tw-px-4 lg:tw-py-2 xl:tw-py-3.5 black--text tw-mb-1" :class="{ 'tw-bg-green-50': option.is_correct, 'tw-bg-red-50': option.id == radioGroup && !option.is_correct }">
        <v-icon v-if="option.id === radioGroup && !option.is_correct" color="red" class="tw-mr-3"> mdi-close-circle-outline </v-icon>
        <v-icon v-if="option.is_correct" color="green" class="tw-mr-3"> mdi-checkbox-marked-circle-outline </v-icon>
        <v-icon v-if="!option.is_correct && option.id != radioGroup" class="tw-mr-3">mdi-checkbox-blank-circle-outline</v-icon>
        {{ option.text }}
      </v-card-text>
      <v-card-text class="tw-pt-0 sm:tw-pt-4 tw-p-2.5 sm:tw-p-4 tw-flex tw-flex-wrap tw-items-center" :class="Answers ? 'tw-justify-between' : 'tw-justify-end'">
        <div class="tw-text-base tw-text-black tw-font-medium tw-order-2 sm:tw-order-1">
          <v-chip v-if="Answers" class="ma-1 ma-sm-2" color="blue" text-color="white">
            <v-avatar left class="blue darken-4"> {{ Answers }} </v-avatar>
            Savol
          </v-chip>
          <v-chip v-if="trueAnswer" class="ma-1 ma-sm-2" color="green" text-color="white">
            <v-avatar left class="green darken-4"> {{ trueAnswer }} </v-avatar>
            To'g'ri
          </v-chip>
          <v-chip v-if="falseAnswer" class="ma-1ma-sm-2" color="red" text-color="white">
            <v-avatar left class="red darken-4"> {{ falseAnswer }} </v-avatar>
            Noto'g'ri
          </v-chip>
        </div>
        <div class="tw-w-full sm:tw-w-auto tw-flex tw-justify-end tw-order-1 sm:tw-order-2">
          <v-btn v-if="show" class="px-2 px-sm-4" color="primary" @click="Next()">Keyingisi</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Random",
  data() {
    return {
      show: false,
      random: 0,
      radioGroup: null,
      Answers: 0,
      trueAnswer: 0,
      falseAnswer: 0,
    };
  },
  mounted() {
    this.getCollectionQuizzes(this.$route.params.id);
    this.randomFunc();
  },
  computed: {
    ...mapGetters(["QUIZZES_LENGTH"]),
    random_quiz() {
      return this.$store.getters.RANDOM_QUIZZES(this.random);
    },
  },
  methods: {
    ...mapActions(["getCollectionQuizzes"]),
    randomFunc() {
      this.random = Math.floor(Math.random() * this.QUIZZES_LENGTH);
      this.show = false;
      this.radioGroup = null;
    },
    showAnswer() {
      if (this.radioGroup) {
        this.show = true;
        this.Answers += 1;
        if (this.random_quiz.options.filter((data) => data.is_correct)[0].id == this.radioGroup) {
          this.trueAnswer += 1;
        } else {
          this.falseAnswer += 1;
        }
      }
    },
    Next() {
      this.randomFunc();
    },
  },
};
</script>

<style lang="scss" scoped></style>
