import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    collections: [],
    collections_workplace: [],
    education_courses: [],
    education_degrees: [],
    education_years: [],
    advertisement: '',
    collection_quizzes: [],
    all_information: '',
    tests_list: [],
    token: '',
    drawer: false,
    quiz_tests_list: "",
    answer: [],
    mytestlist: [],
    mytestdetail: '',
  },
  mutations: {
    SET_COLLECTIONS(state, collections) {
      state.collections = collections
    },
    SET_COLLECTIONS_WORKPLACE(state, collections_workplace) {
      state.collections_workplace = collections_workplace
    },
    SET_EDUCATION_COURSES(state, education_courses) {
      state.education_courses = education_courses
    },
    SET_EDUCATION_DEGREES(state, education_degrees) {
      state.education_degrees = education_degrees
    },
    SET_EDUCATION_YEARS(state, education_years) {
      state.education_years = education_years
    },
    SET_ADVERTISEMENT(state, advertisement) {
      state.advertisement = advertisement
    },
    SET_COLLECTION_QUIZZES(state, collection_quizzes) {
      state.collection_quizzes = collection_quizzes
    },
    SET_ALL_INFORMATION(state, all_information) {
      state.all_information = all_information
    },
    SET_TESTS_LIST(state, tests_list) {
      state.tests_list = tests_list
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_DRAWER(state, boolean) {
      state.drawer = boolean
    },
    SET_QUIZ_TESTS_LIST(state, quiz_tests_list) {
      state.quiz_tests_list = quiz_tests_list
    },
    SET_ANSWER(state, answer) {
      state.answer = answer
    },
    SET_MYTESTLIST(state, mytestlist) {
      state.mytestlist = mytestlist
    },
    SET_MYTESTDETAIL(state, mytestdetail) {
      state.mytestdetail = mytestdetail
    }
  },
  getters: {
    COLLECTIONS: (state) => (search, education_course, education_degree, education_year) => {
      return state.collections.filter(collections => (!search || collections.name.toLowerCase().includes(search.toLowerCase())) && (!education_course || collections.education_course.id == education_course) && (!education_year || collections.education_year.id == education_year) && (!education_degree || collections.education_degree.id == education_degree))
    },
    COLLECTIONS_WORKPLACE: (state) => id => {
      return state.collections_workplace.filter(collections_workplace => (!id || collections_workplace.id == id))[0]
    },
    EDUCATION_COURSES(state) {
      return state.education_courses
    },
    EDUCATION_DEGREES(state) {
      return state.education_degrees
    },
    EDUCATION_YEARS(state) {
      return state.education_years
    },
    ADVERTISEMENT(state) {
      return state.advertisement
    },
    COLLECTION_QUIZZES: (state) => (search) => {
      return state.collection_quizzes.filter(data => (!search || data.question.toLowerCase().includes(search.toLowerCase())))
    },
    QUIZZES_LENGTH(state) {
      return state.collection_quizzes.length
    },
    RANDOM_QUIZZES: (state) => (rand) => {
      return state.collection_quizzes[rand]
    },
    ALL_INFORMATION(state) {
      return state.all_information
    },
    TESTS_LIST(state) {
      return state.tests_list
    },
    TOKEN(state) {
      return state.token
    },
    DRAWER(state) {
      return state.drawer
    },
    QUIZ_TESTS_LIST(state) {
      return state.quiz_tests_list
    },
    ACTIVE_TEST_ID(state) {
      return state.tests_list.find(data => !data.end_time) ? state.tests_list.find(data => !data.end_time).id : null
    },
    MYTESTLIST(state) {
      return state.mytestlist
    },
    MYTESTDETAIL(state) {
      return state.mytestdetail
    }
  },
  actions: {
    async getAuth({ dispatch }, form) {
      await axios.post('auth/', form).then((response) => {
        localStorage.setItem('token', response.data.token)
        dispatch('getToken')
      })
    },
    getToken({ commit }) {
      commit('SET_TOKEN', localStorage.getItem('token') ? localStorage.getItem('token') : '')
    },
    async getCollections({ commit }) {
      await axios.get('core/collections/', {
        headers: {
          'X-Token': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }
      }).then((response) => {
        commit('SET_COLLECTIONS', response.data)
        commit('SET_COLLECTIONS_WORKPLACE', response.data)

      }).catch(() => { })
    },
    async getEducationCourses({ commit }) {
      await axios.get('core/education-courses/').then((response) => {
        commit('SET_EDUCATION_COURSES', response.data)
      }).catch(() => { })
    },
    async getEducationDegrees({ commit }) {
      await axios.get('core/education-degrees/').then((response) => {
        commit('SET_EDUCATION_DEGREES', response.data)
      }).catch(() => { })
    },
    async getEducationYears({ commit }) {
      await axios.get('core/education-years/').then((response) => {
        commit('SET_EDUCATION_YEARS', response.data)
      }).catch(() => { })
    },
    async getAdvertisement({ commit }) {
      await axios.get('core/advertisement/').then((response) => {
        commit('SET_ADVERTISEMENT', response.data)
      }).catch(() => { })
    },
    async getCollectionQuizzes({ commit }, id) {
      await axios.get('core/collections/' + id + '/quizzes/').then((response) => {
        commit('SET_COLLECTION_QUIZZES', response.data.filter(data => data.options.sort(() => (0.5 - Math.random()))))

      }).catch(() => { })
    },
    async getAllInformation({ commit }) {
      await axios.get('core/informations/').then((response) => {
        commit('SET_ALL_INFORMATION', response.data)
      }).catch(() => { })
    },



    async getTestCreate({ dispatch }, id) {
      await axios.post('test/create/', { "collection": id }, {
        headers: {
          'X-Token': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }
      }).then((response) => {

        dispatch('getTestDetail', response.data.id)
      }).catch(() => {
      })
    },
    getTestList({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios.get('test/list/', {
          headers: {
            'X-Token': localStorage.getItem('token') ? localStorage.getItem('token') : ''
          }
        }).then((response) => {
          let active_test = response.data.find(data => !data.end_time)
          if (active_test) {
            if((new Date(new Date(active_test.start_time).getTime() + active_test.collection.duration * 60000).getTime() - (new Date()).getTime()) < 0){
              dispatch('getTestAnswer',{ list: [], id: active_test.id })
              reject()
            }else
            if (id == active_test.collection.id) {
              dispatch('getTestDetail', active_test.id)
              reject()
            }
            else {
              resolve(active_test.collection)
            }
          }
          else {
            reject()
          }
        })
      })
    },
    async getTestDetail({ commit }, id) {
      await axios.get(`test/${id}/`, {
        headers: {
          'X-Token': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }
      }).then((response) => {
        response.data.quizzes = response.data.quizzes.filter(data => data.options.sort(() => (0.5 - Math.random())))
        commit('SET_QUIZ_TESTS_LIST', response.data)
      })
    },
    getTestAnswer({ commit }, { list, id }) {
      let answer = list.filter(data => data != null);
      return new Promise((resolve, reject) => {
        axios.put(`test/${id}/answer/`, { "answers": answer }, {
          headers: {
            'X-Token': localStorage.getItem('token') ? localStorage.getItem('token') : ''
          }
        }).then((response) => {
          resolve(response)
          commit('SET_QUIZ_TESTS_LIST', "")
        }).catch((error) => {
          reject(error)
        })
      })
    },

    async getMyTestList({ commit }) {
      await axios.get('test/list/', {
        headers: {
          'X-Token': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }
      }).then((response) => {
        commit('SET_MYTESTLIST', response.data)
      }
      )
    },
    async getMyTestDetail({ commit }, id) {
      await axios.get(`test/${id}/`, {
        headers: {
          'X-Token': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }
      }).then((response) => {
        response.data.quizzes = response.data.quizzes.filter(data => data.options.sort(() => (0.5 - Math.random())))
        commit('SET_MYTESTDETAIL', response.data)
      })
    }
  },
  modules: {
  }
})
